.interna__conteudo{
	.palestra__listagem{
		li{
			&:before{
				background-color: #000;
			}
		}
	}
}
.owlPalestra.owl-carousel{
	padding: 0px;
	position: relative;

	.owl-item{
		padding: 23px 20px;
	}
	.owl-nav{
		button.owl-prev,
		button.owl-next{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 50px;
			height: 50px;
			border: 2px solid #000;
			background-color: #000;
			margin: 0px 5px;
			transition: all 0.3s linear;
			position: absolute;
			top: 50%;
			transform: translateY(-50%);

			&:focus{
				outline: none;
			}

			i{
				background-color: #fff;
				transition: all 0.3s linear;
			}

			&:hover{
				background-color: #fff;

				i{
					background-color: #000;
				}
			}
		}
		.owl-next{
			right: -50px;
		}
		.owl-prev{
			left: -50px;
		}
	}
}
.palestra__card{
	display: block;
	max-width: 340px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	transition: all 0.3s linear;

	&:hover{
		transform: scale(1.1);
		box-shadow: 0px 0px 5px rgba(#000, 0.6);
	}
}
.sobre--int__header{
	&.contato__palestra{
		margin-bottom: 0px;
		padding: 40px 0px;

		.container{
			display: flex;
			align-items: center;
			justify-content: space-between;

			span{
				max-width: 620px;
				margin-right: auto;
				margin-left: 0px;
				text-align: left;
			}
			a{
				background-color: #000;
				color: #fff;
			}
		}
	}
}
.interna__conteudo{
	&.palestra__interna{
		padding-bottom: 0px;
	}
}
@include media-breakpoint-down (md) {
	.owlPalestra.owl-carousel{
		.owl-nav{
			.owl-next{
				right: -30px;
			}
			.owl-prev{
				left: -30px;
			}
		}
	}
}
@include media-breakpoint-down (sm) {
	.sobre--int__header{
		&.contato__palestra{
			.container{
				flex-direction: column;
				align-items: center;

				span{
					margin-bottom: 20px;
					text-align: center;
				}
			}
		}
	}
}
@include media-breakpoint-down (xs) {
	.owlPalestra.owl-carousel{
		.owl-nav{
			.owl-next{
				right: 0px;
			}
			.owl-prev{
				left: 0px;
			}
		}
	}
}
