.main__menu{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	color: #fff;
}
.main__logo{
	display: block;
}
@include media-breakpoint-up (lg) {
	.topo{
		position: relative;
	}
	.topo__main{
		background-color: rgba(#000, 0.7);
		padding: 15px 0px 10px 0px;
		position: absolute;
		top: 0px;
		left: 50%;
		transform: translateX(-50%);
		width: 100%;
		max-width: 100%;

		&.topo__main--interna{
			background-color: rgba(#000, 1);
			position: relative;
		}
	}
	.topo__mbl{
		display: none;
	}
	.segura__main{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.main__menu{
		flex-grow: 1;
		display: flex;
		justify-content: space-between;
		padding-right: 30px;
		border-right: 2px solid #fff;
	}
	.menu__link{
		display: block;
		padding: 10px 10px 5px 5px;
		border-bottom: 2px solid transparent;
		border-left: 2px solid transparent;
		position: relative;
		transition: all 0.3s linear;

		&:hover{
			font-family: 'montserratextrabold';
			text-decoration: none;
			border-bottom: 2px solid #fff;
			border-left: 2px solid #fff;

			&:after{
				background-color: #fff;
			}
			&:before{
				background-color: #fff;
			}
		}

		&:after{
			width: 6px;
			height: 2px;
			position: absolute;
			top: 0px;
			left: 0px;
			background-color: transparent;
			content: '';
			transition: all 0.3s linear;
		}
		&:before{
			width: 2px;
			height: 6px;
			position: absolute;
			bottom: 0px;
			right: 0px;
			background-color: transparent;
			content: '';
			transition: all 0.3s linear;
		}
	}
	.menu__item--active{
		.menu__link{
			font-family: 'montserratextrabold';
			border-bottom: 2px solid #fff;
			border-left: 2px solid #fff;

			&:after{
				background-color: #fff;
			}
			&:before{
				background-color: #fff;
			}
		}
	}
	.main__logo{
		margin-right: 180px;
	}
	.main__sociais{
		display: flex;
		color: #000;
		padding-left: 30px;

		a{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			background-color: #fff;
			border-radius: 50%;
			transition: all 0.3s linear;
			border: 2px solid #fff;
			margin: 0px 1px;

			&:hover{
				text-decoration: none;
				background-color: transparent;
				color: #fff;
			}
		}
	}
}
@include media-breakpoint-only (lg) {
	.main__logo{
		margin-right: 100px;
	}
}
@include media-breakpoint-down (md) {
	.segura__mbl{
		display: flex;
		justify-content: space-between;
		align-items: center;
	}
	.topo__mbl{
		background-color: rgba(#000, 1);
		padding: 5px 0px;
	}
	.mbl__botao{
		color: #fff;
		font-size: 30px;
		line-height: 30px;
		background-color: transparent;
		border: none;

		&:focus{
			outline: none;
		}
	}
	.topo__main{
		position: fixed;
		top: 0px;
		left: -250px;
		width: 250px;
		height: 100%;
		overflow-y: auto;
		transition: all 0.3s linear;
		background-color: rgba(#000, 1);
		color: #fff;
		z-index: 1000;

		&.topo__main--shown{
			left: 0px;
		}
	}
	.segura__main{
		padding: 0px;
	}
	.main__logo{
		display: block;
		padding: 5px;
		margin-bottom: 20px;
		text-align: center;
	}
	.menu__link{
		display: block;
		padding: 5px;
		transition: all 0.3s linear;
		border-bottom: 1px solid rgba(#fff, 0.3);

		span{
			border-bottom: 2px solid transparent;
			border-left: 2px solid transparent;
			position: relative;
			padding: 10px 10px 5px 5px;
			transition: all 0.3s linear;
			display: inline-block;

			&:after{
				width: 6px;
				height: 2px;
				position: absolute;
				top: 0px;
				left: 0px;
				background-color: transparent;
				content: '';
				transition: all 0.3s linear;
			}
		&:before{
				width: 2px;
				height: 6px;
				position: absolute;
				bottom: 0px;
				right: 0px;
				background-color: transparent;
				content: '';
				transition: all 0.3s linear;
			}
		}

		&:hover{
			font-family: 'montserratextrabold';
			text-decoration: none;

			span{
				border-bottom: 2px solid #fff;
				border-left: 2px solid #fff;

				&:after{
					background-color: #fff;
				}
				&:before{
					background-color: #fff;
				}
			}
		}
	}
	.menu__item--active{
		.menu__link{
			font-family: 'montserratextrabold';

			span{
				border-bottom: 2px solid #fff;
				border-left: 2px solid #fff;

				&:after{
					background-color: #fff;
				}
				&:before{
					background-color: #fff;
				}
			}
		}
	}
	.main__menu{
		margin-bottom: 30px;
		border-top: 1px solid rgba(#fff, 0.3);
	}
	.main__sociais{
		display: flex;
		color: #000;
		justify-content: center;
		align-items: center;

		a{
			display: flex;
			align-items: center;
			justify-content: center;
			width: 30px;
			height: 30px;
			background-color: #fff;
			border-radius: 50%;
			transition: all 0.3s linear;
			border: 2px solid #fff;
			margin: 0px 5px;

			&:hover{
				text-decoration: none;
				background-color: transparent;
				color: #fff;
			}
		}
	}
	.bg__menu{
		position: fixed;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: rgba(#000, 0.6);
		z-index: 999;
		animation: fadeIn 0.6s linear;

		&.hide{
			animation: fadeOut 0.6s linear;
		}
	}
}
