.interna__conteudo{
	.blog__card{
		margin-bottom: 30px;
	}
}
.paginacao{
	display: flex;
	justify-content: center;
	font-family: 'montserratblack';
	font-size: 18px;
	line-height: 18px;
}
.paginacao__item{
	display: block;
	padding: 5px;
	margin: 0px 5px;

	&.paginacao__item--active{

	}
}
.contato__input{
	border: 2px solid #000;
	height: auto;
	padding: 15px 22px;
	background-color: transparent;
	color: #000;
	display: block;
	width: 100%;
	resize: none;
	font-family: 'montserratmedium';
	font-size: 13px;
	line-height: 17px;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #000;
	}
}
.segura__contato__btn{
	display: flex;
	border: 2px solid #000;
	align-items: flex-end;
	padding: 0px 5px 5px 0px;

	.contato__input{
		border: none;
	}
}
.btn__form__contato{
	border-radius: 50%;
	background-color: #000;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 46px;
	min-width: 46px;
	height: 46px;

	span{
		background-color: #fff;
		margin-right: 5px;
	}

	&:focus{
		outline: none;
	}
}
.contato--int__desc{
	text-align: center;
	margin-bottom: 30px;
}
.contato--int__form{
	max-width: 925px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
.sobre--int__titulo{
	&.contato--int{
		max-width: 900px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
	}
}
.contato--int__desc{
	max-width: 900px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
}
