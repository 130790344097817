.breadcrump__interna{
	background-color: #d4d4d4;
	color: #000;
	padding: 15px 0px;
	font-size: 14px;
	line-height: 14px;
	margin-bottom: 40px;

	.barrinha,
	a{
		font-family: 'montserratextrabold';
	}
}
.segura__breadcrump{
	display: flex;
	justify-content: flex-end;
}
.interna__img{
	text-align: center;
	margin-bottom: 30px;
}
.sobre--int__titulo{
	max-width: 900px;
	width: 100%;
	margin-right: auto;
	margin-left: auto;
	text-align: center;
	font-family: 'montserratblack';
	font-size: 30px;
	line-height: 34px;
	margin-bottom: 20px;
}
.sobre--int__desc{
	text-align: justify;
	margin-bottom: 20px;
}
.sobre--int__header{
	padding: 54px 0px;
	position: relative;
	background-image: url('../images/bg__header.jpg');
	background-size: cover;
	background-position: center top;
	background-repeat: no-repeat;
	font-family: 'montserratblack';
	font-size: 30px;
	line-height: 34px;
	color: #000;
	text-align: center;
	margin-bottom: 40px;
}
.interna__conteudo{
	padding-bottom: 40px;
}
@include media-breakpoint-down (sm) {
	.sobre--int__header,
	.sobre--int__titulo{
		font-size: 24px;
		line-height: 28px;
	}
}
