.banner__carousel{
	overflow: hidden;
	width: 100%;
	max-width: 1920px;
	margin-right: auto;
	margin-left: auto;
	position: relative;
}
.banner__inner{
	width: 160%;
	max-width: 1920px;
	position: relative;
	left: 50%;
	transform: translateX(-50%);
}
.banner__indicators{
	justify-content: flex-start;
	margin-bottom: 30px;

	li{
		width: 30px;
		height: 30px;
		border-radius: 50%;
		background-color: #fff;
		opacity: 1;
		border: none;
		transition: all 0.3s linear;
		position: relative;

		&:after{
			width: 30px;
			height: 30px;
			border: 2px solid #fff;
			content: '';
			position: absolute;
			top: 0px;
			left: 0px;
			border-radius: 50%;
		}

		&.active{
			background-color: transparent;
		}
	}
}
.numero__card{
	display: flex;
	align-items: center;
	justify-content: center;
}
.numeros{
	padding: 50px 0px 100px 0px;
}
.num__icone{
	margin-right: 20px;
}
.num__titulo{
	font-family: 'montserratblack';
	font-size: 30px;
	color: #000;
	line-height: 34px;
}
.num__desc{
	font-family: 'montserratsemibold';
	font-size: 18px;
	line-height: 20px;
	color: #000;
}
.numero__titulo{
	font-family: 'montserratblack';
	font-size: 30px;
	line-height: 34px;
	text-align: center;
	padding-top: 70px;
}
.sobre{
	position: relative;
	padding: 50px 0px;
	color: #fff;

	&:before{
		width: 100%;
		height: 100%;
		content: '';
		position: absolute;
		z-index: -2;
		background-color: #000;
		top: 0px;
		left: 0px;
	}
	&:after{
		top: 50px;
		content: '';
		position: absolute;
		z-index: -1;
		width: 1920px;
		height: calc(100% - 50px);
		left: 50%;
		transform: translateX(-50%);
		background-image: var(--imagem);
		background-repeat: no-repeat;
		background-size: auto auto;
		background-position: left top;
	}
}
.sobre__header{
	width: 100%;
	max-width: 770px;
	margin-left: auto;
	margin-right: 0px;
	margin-bottom: 160px;
	margin-top: 120px;
}
.sobre__conteudo{
	width: 100%;
	max-width: 770px;
	margin-left: auto;
	margin-right: 0px;
}
.sobre__header{
	border-left: 7px solid #fff;
	border-bottom: 7px solid #fff;
	padding: 0px 0px 30px 15px;
	position: relative;
	color: #fff;

	&:after{
		width: 7px;
		height: 25px;
		content: '';
		position: absolute;
		background-color: #fff;
		bottom: 0px;
		right: 0px;
	}
}
.titulo__secao{
	font-family: 'montserratsemibold';
	font-size: 13px;
	line-height: 17px;
	margin-bottom: 30px;
	position: relative;
	padding-left: 30px;
	transform: translateY(-4px);

	&:before{
		position: absolute;
		width: 25px;
		height: 7px;
		content: '';
		background-color: currentColor;
		left: -15px;
		top: 4px;
	}
}
.sub__secao{
	font-family: 'montserratblack';
	font-size: 30px;
	line-height: 34px;
}
.sobre__desc{
	text-align: justify;
	margin-bottom: 20px;
}
.btn__conteudo{
	display: flex;
	width: 160px;
	height: 50px;
	align-items: center;
	justify-content: center;
	background-color: #fff;
	color: #000;
	font-family: 'montserratsemibold';
	font-size: 13px;
	line-height: 13px;

	&:hover{
		text-decoration: none;
		color: #000;
	}
}
.treinamento{
	padding: 50px 0px 60px 0px;
}
.treinamento__header{
	border-left: 7px solid #000;
	border-bottom: 7px solid #000;
	padding: 0px 0px 30px 15px;
	position: relative;
	color: #000;
	max-width: 560px;
	margin-right: auto;
	margin-left: 0px;
	margin-bottom: 30px;

	&:after{
		width: 7px;
		height: 25px;
		content: '';
		position: absolute;
		background-color: #000;
		bottom: 0px;
		right: 0px;
	}
}
.treinamento__desc{
	text-align: justify;
	margin-bottom: 40px;
}
.treinamento__btn{
	margin-bottom: 34px;

	.btn__conteudo{
		background-color: #000;
		color: #fff;

		&:hover{
			background-color: #000;
			color: #fff;
		}
	}
}
.treinamento__controls{
	display: flex;
	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 50px;
		height: 50px;
		border: 2px solid #000;
		background-color: #000;
		margin: 0px 5px;
		transition: all 0.3s linear;

		&:first-child{
			margin: 0px 5px 0px 0px;
		}

		span{
			background-color: #fff;
			transition: all 0.3s linear;
		}

		&:hover{
			background-color: #fff;

			span{
				background-color: #000;
			}
		}
	}
	.treinamento__next{
		span{
			margin-left: 2px;
		}
	}
}
.owlTreinamento{
	.owl-item{
		padding: 35px 0px;
		position: relative;
		z-index: 5;

		&:hover{
			z-index: 10;
		}
	}
}
.treinamento__card{
	display: block;
	position: relative;
	transition: all 0.3s linear;

	&:after{
		content: '';
		position: absolute;
		bottom: 0px;
		left: 0px;
		width: 100%;
		height: 188px;
		background-image: url('../images/bg__card.png');
		background-repeat: no-repeat;
		background-size: 100% 188px;
		background-position: center bottom;
	}

	&:hover{
		text-decoration: none;
		transform: scale(1.1);
		box-shadow: 0px 0px 10px rgba(#000, 0.8);

		.treinamento__img{
			img{
				filter: grayscale(0%);
			}
		}
		.treinamento__txt{
			opacity: 1;
		}
	}
}
.treinamento__img{
	img{
		filter: grayscale(100%);
		transition: all 0.3s linear;
	}
}
.treinamento__txt{
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	height: auto;
	padding: 20px 20px 20px 20px;
	z-index: 2;
	opacity: 0;
	transition: all 0.3s linear;
}
.titulo__treinamento{
	font-family: 'montserratblack';
	font-size: 17px;
	line-height: 21px;
	color: #fff;
	--linhas: 2;
	margin-bottom: 10px;
}
.treinamento__botao{
	font-family: 'montserratsemibold';
	font-size: 13px;
	line-height: 13px;
	color: #fff;
}
.alunos__img{
	width: 292px;
	height: 292px;
	max-width: 100%;
	padding: 15px 15px 10px 10px;
	border-left: 5px solid #000;
	border-bottom: 5px solid #000;
	position: relative;
	text-align: left;
	margin-right: auto;
	margin-left: 0px;
	display: flex;
	align-items: center;
	margin-bottom: 25px;

	&:after{
		content: '';
		position: absolute;
		height: 5px;
		width: 20px;
		top: 0px;
		left: 0px;
		background-color: #000;
	}
	&:before{
		content: '';
		position: absolute;
		width: 5px;
		height: 20px;
		bottom: 0px;
		right: 0px;
		background-color: #000;
	}
}
.alunos__header{
	margin-bottom: 60px;
}
.alunos__conteudo{
	width: 292px;
	max-width: 100%;
	margin-bottom: 20px;
}
.alunos__nome{
	font-family: 'montserratblack';
	font-size: 18px;
	line-height: 22px;
	color: #000;
	margin-bottom: 10px;
}
.alunos__desc{
	text-align: justify;
}
.alunos{
	padding: 40px 0px 50px 0px;
}
.palestra{
	position: relative;
	color: #fff;

	&:before{
		width: 100%;
		height: 100%;
		background-color: #000;
		top: 0px;
		left: 0px;
		content: '';
		z-index: -1;
		position: absolute;
	}
}
.palestra__txt{
	padding: 44px 0px;
	max-width: 580px;
	width: 100%;
	margin-right: 0px;
	margin-left: auto;
}
.palestra__header{
	border-left: 7px solid #fff;
	border-bottom: 7px solid #fff;
	padding: 0px 0px 30px 15px;
	position: relative;
	color: #fff;
	margin-bottom: 40px;

	&:after{
		width: 7px;
		height: 25px;
		content: '';
		position: absolute;
		background-color: #fff;
		bottom: 0px;
		right: 0px;
	}
}
.palestra__desc{
	margin-bottom: 25px;
	text-align: justify;
	font-family: 'montserratmedium';
}
.palestra__listagem{
	list-style: none;
	padding-left: 0px;
	margin-bottom: 0px;
	font-family: 'montserratmedium';
	margin-bottom: 58px;

	li{
		padding-left: 32px;
		margin-bottom: 12px;
		position: relative;

		&:before{
			content: '';
			position: absolute;
			width: 8px;
			height: 8px;
			background-color: #fff;
			border-radius: 50%;
			left: 0px;
			top: 50%;
			transform: translateY(-50%);
		}
	}
}
.palestra__img{
	position: relative;

	&:after{
		content: '';
		position: absolute;
		bottom: 0px;
		right: 0px;
		background-image: url('../images/detalhe__palestra.png');
		background-repeat: no-repeat;
		background-size: cover;
		background-position: center;
		width: 102px;
		height: 236px;
		z-index: 2;
	}
}
.contato__btn{
	display: flex;
	align-items: center;
	justify-content: space-between;

	span{
		font-family: 'montserratblack';
		font-size: 13px;
		line-height: 17px;
	}
}
.blog{
	padding: 60px 0px 50px 0px;
}
.contato__header{
	margin-bottom: 55px;
}
.blog__card{
	width: 100%;
	max-width: 256px;
	margin-right: auto;
	margin-left: auto;
	display: block;
	padding: 36px 20px;
	position: relative;

	&:hover{
		text-decoration: none;
		color: #fff;

		&:before{
			background-color: rgba(#000000, 0.55);
		}
		.blog__link{
			opacity: 1;
		}
	}

	&:before{
		content: '';
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-color: #d8d5d5;
		z-index: -1;
		transition: all 0.3s linear;
	}
	&:after{
		content: '';
		position: absolute;
		top: 0px;
		left: 0px;
		width: 100%;
		height: 100%;
		background-image: url('../images/bg__noticia.jpg');
		background-repeat: no-repeat;
		background-position: center top;
		background-size: auto 100%;
		z-index: -2;
	}
}
.titulo__blog{
	font-family: 'montserratblack';
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 7px;
	height: 44px;
	transition: all 0.3s linear;
}
.blog__data{
	margin-bottom: 15px;
	transition: all 0.3s linear;
	font-family: 'montserratsemibold';
}
.blog__desc{
	text-align: justify;
	margin-bottom: 30px;
	transition: all 0.3s linear;
	font-family: 'montserratsemibold';
}
.blog__link{
	font-family: 'montserratblack';
	font-size: 13px;
	line-height: 17px;
	text-decoration: underline;
	transition: all 0.3s linear;
	opacity: 0;
}
.blog__btn{
	padding-top: 50px;

	.btn__conteudo{
		margin-right: auto;
		margin-left: auto;
		color: #fff;
		background-color: #000;
	}
}
@include media-breakpoint-up (xl) {
	.treinamento__desc{
		padding-top: 70px;
	}
}
@include media-breakpoint-down (lg) {
	.palestra__row{
		align-items: center;
	}
}
@include media-breakpoint-down (md) {
	.treinamento__card{
		max-width: 240px;
		width: 100%;
		margin-right: auto;
		margin-left: auto;
		box-shadow: 0px 0px 10px rgba(#000, 0.5);

		&:hover{
			transform: scale(1);
			box-shadow: 0px 0px 10px rgba(#000, 0.5);
		}
	}
	.treinamento__img{
		img{
			filter: grayscale(0%);
		}
	}
	.treinamento__btn{

		.btn__conteudo{
			margin-right: auto;
			margin-left: auto;
		}
	}
	.treinamento__controls{
		justify-content: center;
		margin-bottom: 20px;
	}
	.owlCarousel{
		.owl-item{
			padding: 5px;
		}
	}
	.numero__card{
		flex-direction: column;
		align-items: center;
		text-align: center;
		margin-bottom: 20px;
	}
	.num__icone{
		margin-bottom: 5px;
		margin-right: 0px;
	}
	.alunos__conteudo{
		margin-right: auto;
		margin-left: auto;
		text-align: center;
		margin-bottom: 40px;
	}
	.alunos__header{
		text-align: center;
	}
	.palestra__row{
		.col-lg-5{
			order: 2;
			text-align: center;
		}
		.col-lg-7{
			order: 1;
		}
	}
	.palestra__txt{
		width: 100%;
		max-width: 100%;
	}
	.blog__card{
		margin-bottom: 30px;
		color: #fff;

		.blog__link{
			opacity: 1;
		}

		&:before{
			background-color: rgba(#000000, 0.55);
		}
	}
}
@include media-breakpoint-down (xs) {
	.sub__secao,
	.numero__titulo{
		font-family: 'montserratblack';
		font-size: 22px;
		line-height: 26px;
	}
	.contato__btn{
		flex-direction: column;

		span{
			margin-bottom: 20px;
		}
	}
}
