.rodape{
	background-color: #000;
	color: #fff;
}
.rodape__conteudo{
	padding-top: 50px;
	padding-bottom: 50px;
}
.logo__rodape{
	margin-bottom: 20px;
}
.rodape__desc{
	text-align: justify;
	margin-bottom: 40px;
	font-family: 'montserratmedium';
}
.rodape__sociais{
	display: flex;

	a{
		display: flex;
		align-items: center;
		justify-content: center;
		width: 30px;
		height: 30px;
		background-color: #fff;
		border-radius: 50%;
		color: #000;
		margin: 0px 2px;
		transition: all 0.3s linear;
		border: 2px solid #fff;

		&:hover{
			text-decoration: none;
			background-color: #000;
			color: #fff;
		}
	}
}
.rodape__titulo{
	font-family: 'montserratblack';
	font-size: 18px;
	line-height: 22px;
	margin-bottom: 24px;
}
.box__titulo{
	font-family: 'montserratblack';
	font-size: 13px;
	line-height: 17px;
}
.rodape__box{
	margin-bottom: 20px;
	font-family: 'montserratmedium';
	font-size: 13px;
	line-height: 17px;
}
.box__mail,
.box__tel{
	display: flex;
	flex-direction: column;
}
.rodape__input{
	border: 2px solid #fff;
	height: auto;
	padding: 15px 22px;
	background-color: transparent;
	color: #fff;
	display: block;
	width: 100%;
	resize: none;
	font-family: 'montserratmedium';
	font-size: 13px;
	line-height: 17px;

	&:focus{
		outline: none;
	}

	&::placeholder{
		color: #fff;
	}
}
.segura__btn{
	display: flex;
	border: 2px solid #fff;
	align-items: flex-end;
	padding: 0px 5px 5px 0px;

	.rodape__input{
		border: none;
	}
}
.btn__form{
	border-radius: 50%;
	background-color: #fff;
	border: none;
	display: flex;
	align-items: center;
	justify-content: center;
	width: 46px;
	min-width: 46px;
	height: 46px;

	&:focus{
		outline: none;
	}
}
.segura__creditos{
	display: flex;
	justify-content: space-between;
	align-items: center;
}
.rodape__creditos{
	border-top: 1px solid #fff;
	font-size: 10px;
	line-height: 14px;
	padding: 14px 0px;
}
.gv8__box{
	display: flex;
	align-items: center;

	.gv8__logo{
		margin-left: 5px;
	}
}
@include media-breakpoint-up (lg) {
	.rodape__conteudo{
		.col-lg-4{
			padding-right: 60px;
		}
	}
}
@include media-breakpoint-down (md) {
	.logo__rodape{
		text-align: center;
	}
	.rodape__desc{
		text-align: center;
		margin-bottom: 20px;
	}
	.rodape__sociais{
		justify-content: center;
		margin-bottom: 20px;
	}
	.rodape__titulo{
		text-align: center;
	}
	.box__titulo{
		text-align: center;
	}
	.box__conteudo{
		text-align: center;
	}
}
